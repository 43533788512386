import _get from 'lodash.get';
import _set from 'lodash.set';

// set default field value based on field type
export const getDefaultValue = (field, fieldName, quoteData) => {
    // get the value from api data if exists
    let storedValue = _get(quoteData, fieldName);

    if (field.type === 'repeater' && Array.isArray(storedValue) && storedValue.length === 0 && storedValue.length !== undefined) {
        const fieldsObj = {};
        field.fields.flat().forEach((field) => (fieldsObj[field.name] = ''));
        storedValue[0] = fieldsObj;
    }

    return {
        boolean: storedValue,
        date: storedValue || '',
        number: storedValue || '',
        repeater: storedValue || [],
        select: storedValue || null,
        text: storedValue || '',
        pid: storedValue || '',
        country: storedValue || '',
        financier: storedValue || '',
        checkbox: storedValue || false,
        signature: storedValue || false,
        textarea: storedValue || '',
        file: storedValue || '',
        state: storedValue || '',
    }[field.type];
};

export const getFieldName = (item, groupFieldName) => {
    if (item.type === 'repeater') {
        return `${item.name}`;
    }

    if (item.groupFieldName) {
        return `${item.groupFieldName}.${item.name}`;
    }

    if (groupFieldName) {
        return `${groupFieldName}.${item.name}`;
    }

    return `${item.name}`;
};

// recursive function to get nested field arrays - need to construct with bracket notation (eg. personal[first_name])
export const getInitialValues = (stage, quoteData) => {
    let initialValues = {};
    let groups = [];

    const getFields = (fields, group) => {
        if (Array.isArray(fields)) {
            fields.map((field) => {
                return getFields(field, group.groupFieldName);
            });
        } else {
            const fieldName = getFieldName(fields, group.groupFieldName);

            if (fields.type !== 'total') {
                // use lodash set util to set the object property - adds safety check prevent breaking if property doesn't exist
                _set(initialValues, fieldName, getDefaultValue(fields, fieldName, quoteData));
            }
        }
    };

    stage.sections.forEach((section) =>
        section.groups.forEach((group) => {
            groups.push(group);
            group.fields.forEach((fields) => getFields(fields, group));
        })
    );

    return { initialValues, groups };
};

export const checkCondition = (condition, formValues) => {
    let conditionFieldValue;

    // if singular field condition
    if (condition.field) {
        conditionFieldValue = _get(formValues, `${condition.field}`);
        const isEmpty = typeof conditionFieldValue === 'string' ? conditionFieldValue.trim() === '' : false;

        if (condition.compare === 'string') {
            return conditionFieldValue === condition.value;
        }

        if (condition.compare === 'in') {
            return Array.isArray(condition.value) && condition.value.includes(conditionFieldValue) !== false;
        }

        if (condition.compare === 'not_in') {
            return Array.isArray(condition.value) && condition.value.includes(conditionFieldValue) === false;
        }

        // Convert numeric values to float to avoid octal literal issues
        if (!isEmpty && typeof conditionFieldValue !== 'boolean' && !isNaN(conditionFieldValue)) {
            conditionFieldValue = parseFloat(conditionFieldValue, 10);
        }

        // eslint-disable-next-line
        return eval(`${conditionFieldValue || 0} ${condition.compare} ${condition.value}`);
        // if we're checking multiple fields
        // the only current use-case for this is when we're checking the driving history has at least one boolean checked
    } else if (condition.fields && Array.isArray(condition.fields)) {
        return condition.fields.some((field) => {
            return _get(formValues, `${field}`) === true;
        });
    }
};
