import { useState, useCallback, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import { useField, useFormikContext } from 'formik';
import { Box } from '@theme-ui/components';
import { useAddressSearch } from 'hooks/useAddressSearch';
import { useAddressDetail } from 'hooks/useAddressDetail';
import { Label } from '../Label';
import { Error } from '../Error';
import { customStyles } from '../Select/styled';
import { theme } from 'theme';
import { useCountries } from 'hooks/useCountries';

export const AddressAutocomplete = ({ name, groupFieldName, label, required, onChange, className, ...props }) => {
    const [searchText, setSearchText] = useState('');
    const [flagFetch, setFlagFetch] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { setFieldValue, values } = useFormikContext();
    const [field, meta] = useField(name);
    const { data: suggestions, isLoading: isLoadingSuggestions } = useAddressSearch(searchText);
    const { data: addressDetail, isLoading: isLoadingDetail } = useAddressDetail(flagFetch);
    const { data: countries } = useCountries();

    const getBasePath = useCallback(() => {
        const parts = name.split('.address_1');
        return parts[0];
    }, [name]);

    const handleInputChange = (newValue, { action }) => {
        if (action === 'input-change') {
            setSearchText(newValue);
            setFlagFetch(null);
        }
    };

    const currentPaths = useMemo(
        () => ({
            address_detail_pid: `${getBasePath()}.address_detail_pid`,
            address_1: `${getBasePath()}.address_1`,
            address_2: `${getBasePath()}.address_2`,
            suburb: `${getBasePath()}.suburb`,
            state: `${getBasePath()}.state`,
            postcode: `${getBasePath()}.postcode`,
            country_id: `${getBasePath()}.country_id`,
        }),
        [getBasePath]
    );

    const handleChange = useCallback(
        (selected) => {
            // clear the search text when an option is selected
            setSearchText('');

            if (!selected) {
                const emptyAddress = {
                    address_detail_pid: '',
                    address_1: '',
                    address_2: '',
                    suburb: '',
                    postcode: '',
                    state: '',
                    country_id: '',
                };

                // clear UI fields with empty strings
                Object.keys(emptyAddress).forEach((key) => {
                    if (currentPaths[key]) {
                        setFieldValue(currentPaths[key], '', false);
                    }
                });
                // trigger onChange with empty values to API to ensure fields are cleared
                if (onChange) {
                    onChange({
                        name: getBasePath(),
                        value: emptyAddress,
                        type: 'address',
                    });
                }

                setFlagFetch(null);
                return;
            }

            // Set the PID to trigger the detail fetch
            if (!isSubmitting) {
                setFlagFetch(selected.value);
            }
        },
        [setFieldValue, currentPaths, onChange, getBasePath, isSubmitting]
    );

    // Effect to handle address detail updates
    useEffect(() => {
        const updateAddress = async () => {
            if (addressDetail && !isSubmitting) {
                try {
                    setIsSubmitting(true);

                    // Find matching country ID from countries list. for Now, fixed to "AU"
                    const countryMatch = countries?.find((country) => country.name === 'Australia');
                    const countryId = countryMatch?.country_id || '';

                    const addressFields = ['address_detail_pid', 'address_1', 'address_2', 'suburb', 'postcode', 'state', 'country_id'];

                    // Set all form values silently first for UI fields
                    addressFields.forEach((field) => {
                        const value = field === 'country_id' ? countryId : addressDetail[field];
                        setFieldValue(currentPaths[field], value, false);

                        // Also update the autocomplete field itself when processing address_1
                        if (field === 'address_1') {
                            setFieldValue(name, value, false);
                        }
                    });

                    if (onChange) {
                        const addressValues = addressFields.reduce(
                            (acc, field) => ({
                                ...acc,
                                [field]: field === 'country_id' ? countryId : addressDetail[field],
                            }),
                            {}
                        );

                        await onChange({
                            name: getBasePath(),
                            value: addressValues,
                            type: 'address',
                        });
                    }
                } catch (error) {
                    console.error('Error updating address:', error);
                } finally {
                    setIsSubmitting(false);
                    // clear the flag after successful update
                    setFlagFetch(null);
                }
            }
        };

        updateAddress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressDetail, countries, isSubmitting, setFieldValue, name, onChange, getBasePath, values]);

    // Memoize options
    const options = useMemo(() => {
        const addressList = suggestions?.data || [];
        return (
            addressList.map((address) => ({
                value: address.address_detail_pid,
                label: address.address,
                data: address,
            })) || []
        );
    }, [suggestions]);

    const findValueFromOptions = useCallback(
        (value) => {
            if (!value) return null;

            // First try to find in options
            const matchingOption = options.find((option) => option.value === value);
            if (matchingOption) {
                // Return only street address for display
                return {
                    ...matchingOption,
                    label: matchingOption.data.address_1,
                };
            }

            // Get the current address values from form state
            const parts = name.split(/[[\].]/).filter(Boolean);
            let currentAddress = values;

            // Traverse the nested object structure
            for (const part of parts.slice(0, -1)) {
                currentAddress = currentAddress?.[part];
            }

            // If we have address data in form state, create a select option format
            if (currentAddress?.address_1) {
                return {
                    value: currentAddress.address_detail_pid,
                    label: currentAddress.address_1,
                    data: currentAddress,
                };
            }

            return null;
        },
        [name, values, options]
    );

    const stylesOverride = {
        ...customStyles,
        control: (provided, state) => ({
            ...customStyles.control(provided, state),
            borderColor: meta.touched && meta.error ? '#EF404A' : state.isFocused ? theme.colors.primary : theme.colors.grayLight,
            '&:hover': {
                borderColor: meta.touched && meta.error ? '#EF404A' : state.isFocused ? theme.colors.primary : theme.colors.grayLight,
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    return (
        <Box sx={{ position: 'relative', mb: 3 }} className={className}>
            {label && <Label htmlFor={name} text={`${label}${required ? '*' : ''}`} sx={{ position: 'absolute', zIndex: 1, left: 15, top: '7px' }} />}
            <Error
                text={meta.error}
                isVisible={meta.touched && meta.error}
                sx={{
                    position: 'absolute',
                    right: '13px',
                    top: '7px',
                }}
            />
            <ReactSelect
                {...field}
                inputValue={searchText}
                value={findValueFromOptions(field.value)}
                onInputChange={handleInputChange}
                onChange={handleChange}
                options={options}
                isLoading={isLoadingSuggestions || isLoadingDetail}
                styles={stylesOverride}
                isClearable
                invalid={meta.touched && meta.error}
                placeholder="Start typing an address..."
                noOptionsMessage={() => (searchText.length > 2 ? 'No addresses found' : 'Type at least 3 characters')}
            />
        </Box>
    );
};
