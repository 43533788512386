import { useContext, memo, useCallback } from 'react';
import { Grid } from '@theme-ui/components';
import { useParams } from 'react-router';
import { useFormikContext } from 'formik';
import { AppContext } from 'context/AppContext';
import { MarkdownContext } from 'context/MarkdownContext';
import { checkCondition } from '../FormSections/helpers';
import { useSubmitQuoteData } from 'pages/Acceptance/hooks/useSubmitQuoteData';
import { FormField } from './FormField';

const FormFieldsMemoized = ({ fields, repeaterName, repeatIndex, groupFieldName, repeater, ...props }) => {
    const { quoteUuid } = useParams();
    const { values } = useFormikContext();
    const app = useContext(AppContext);
    const markdown = useContext(MarkdownContext);
    const submitQuoteData = useSubmitQuoteData();

    const getFieldName = useCallback(
        ({ type, name, ...rest }) => {
            if (['repeater', 'file'].includes(type)) {
                return `${name}`;
            }

            // check if there's an override for group field name
            let groupFieldNameVar = rest.groupFieldName ? rest.groupFieldName : groupFieldName;

            // might not need this
            if (repeater) {
                return `${repeaterName}[${repeatIndex}][${name}]`;
            }

            if (groupFieldNameVar || repeatIndex === false) {
                return `${groupFieldNameVar}.${name}`;
            }

            if (repeatIndex !== false) {
                return `${repeaterName}[${repeatIndex}][${name}]`;
            }

            return name;
        },
        [repeaterName, repeatIndex, groupFieldName, repeater]
    );

    const getApiFieldName = useCallback(
        ({ type, name, ...rest }) => {
            // need to construct the field name differently if it's a repeater vs normal field (with or without group property)
            if (['repeater', 'file'].includes(type)) {
                return `${name}`;
            }

            // check if there's an override for group field name
            let groupFieldNameVar = rest.groupFieldName ? rest.groupFieldName : groupFieldName;

            if (groupFieldNameVar || repeatIndex === false) {
                return `${groupFieldNameVar}[${name}]`;
            }

            if (repeatIndex !== false) {
                return `${repeaterName}[${repeatIndex}][${name}]`;
            }

            return name;
        },
        [repeaterName, repeatIndex, groupFieldName]
    );

    const handleFieldChange = useCallback(
        async ({ name, value, type, callback, notForm }) => {
            // console.log('handleFieldChange input:', { name, value, type });

            // Only handle special case for address batch updates if there are updates. it needs to wait for all other address fields updates to be submitted
            if (type === 'address' && value?.updates) {
                const basePath = name.split('.address_1')[0];

                const addressData = {
                    addrss_detail_pid: value.updates.find((u) => u.name.includes('address_detail_pid'))?.value || '',
                    address_1: value.updates.find((u) => u.name.includes('address_1'))?.value || '',
                    address_2: value.updates.find((u) => u.name.includes('address_2'))?.value || null,
                    suburb: value.updates.find((u) => u.name.includes('suburb'))?.value || '',
                    state: value.updates.find((u) => u.name.includes('state'))?.value || '',
                    postcode: value.updates.find((u) => u.name.includes('postcode'))?.value || '',
                    country_id: value.updates.find((u) => u.name.includes('country_id'))?.value || '',
                };

                // Check if any values have actually changed before submitting
                const currentValues = values[basePath.split('.')[0]]?.address || {};
                const hasChanges = Object.entries(addressData).some(([key, value]) => currentValues[key] !== value);

                if (!hasChanges) {
                    return;
                }

                try {
                    await submitQuoteData.mutateAsync({
                        name: basePath,
                        value: addressData,
                        type: 'address',
                        callback,
                        quoteUuid,
                    });
                } catch (error) {
                    console.error('Error submitting address data:', error);
                }
            } else {
                try {
                    // for repeater fields, need to ensure value is properly formatted
                    let processedValue = value;
                    const isRepeater = Array.isArray(value) && value.length > 0 && typeof value[0] === 'object';

                    if (isRepeater) {
                        // keep array structure aensure each object is properly formatted (remove any undefined or null values)
                        processedValue = value.map((item) => {
                            return Object.fromEntries(Object.entries(item).filter(([_, val]) => val != null));
                        });
                    }

                    const response = await submitQuoteData.mutateAsync({
                        name,
                        value: processedValue,
                        type: isRepeater ? 'repeater' : type,
                        callback,
                        notForm,
                        quoteUuid,
                        repeater: isRepeater,
                    });

                    // Ensure callback is called with the correct value for file uploads
                    if (type === 'file' && callback && response?.data?.data?.[name]) {
                        callback(response.data.data[name]);
                    }
                } catch (error) {
                    console.error('Error submitting field data:', error);
                }
            }
        },
        [submitQuoteData, quoteUuid, values]
    );
    const renderField = useCallback(
        ({ type, name, condition, mdContent, hidden, ...rest }) => {
            if (!type) {
                return 'Error: Missing field type';
            }

            if (hidden) {
                return '';
            }

            if (condition) {
                if (repeatIndex !== false) {
                    condition = { ...condition, field: condition.field.replace('{{repeatIndex}}', repeatIndex) };
                }
                // if doesn't meet condition, don't render field group
                if (!checkCondition(condition, values)) {
                    return '';
                }
            }

            // Set financier content terms and conditions required
            mdContent = mdContent === 'financier' ? `financier-${app.state.financier_id}` : mdContent;

            const fieldProps = {
                key: getFieldName({ type, name, ...rest }),
                name: getFieldName({ type, name, ...rest }),
                apiName: getApiFieldName({ type, name, ...rest }),
                mdContent: mdContent ? markdown[mdContent] : false,
                quoteUuid,
                // remove on change for repeater sub-fields, all values to be sent together
                onChange: !repeaterName ? (args) => handleFieldChange({ ...args, quoteUuid }) : () => false,
                ...rest,
            };

            return <FormField type={type} fieldProps={fieldProps} />;
        },
        [app.state.financier_id, getFieldName, getApiFieldName, markdown, quoteUuid, repeatIndex, values, repeaterName, handleFieldChange]
    );

    const renderFieldRows = (fields) => {
        if (Array.isArray(fields)) {
            return fields.map((field, index) => {
                return (
                    <Grid key={index} columns={[1, null, field.length > 2 ? field.length : 1, field.length]} gap={2}>
                        {renderFieldRows(field, index)}
                    </Grid>
                );
            });
        } else {
            return renderField(fields);
        }
    };

    return renderFieldRows(fields);
};

FormFieldsMemoized.defaultProps = {
    fields: [],
    repeat: false,
    repeatIndex: false,
    repeater: false,
};

export const FormFields = memo(FormFieldsMemoized);
