import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from 'config/api';

export const useValidateQuote = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (quoteUuid) => {
            try {
                let response = await api.get(`/quote/validate/${quoteUuid}`);
                return response.data;
            } catch (e) {
                toast.error(e.response?.data?.message, {
                    toastId: 'validate-quote-error',
                });
                return e.response?.data;
            }
        },
        {
            onSuccess: (data, variables) => {
                if (data.success && data.data) {
                    queryClient.setQueryData('quote', () => data.data);

                    let message = 'Quote progress saved.';
                    const required_count = Object.keys(data?.data?.required_fields).length;
                    if (required_count > 0) {
                        const field = required_count === 1 ? 'field' : 'fields';
                        message += `. You have ${required_count} more required ${field} to review`;
                    }
                    toast.info(message, {
                        toastId: 'quote-saved',
                    });
                }
            },
        }
    );
};
