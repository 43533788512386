import { formData } from 'formData';
import produce from 'immer';
import React, { createContext, useEffect, useReducer } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const initialState = {
        formData: [...formData],
        active: {},
        financier_id: '',
    };

    const appReducer = (state, action) => {
        switch (action.type) {
            case 'SET_GROUP_VALID':
                // Return current state if no active stage
                if (state.active.stage === false || state.active.stage === undefined) {
                    return state;
                }

                const nextState = produce(state, (draftState) => {
                    draftState.formData[state.active.stage].sections[action.payload.sectionIndex].groups[action.payload.groupIndex].isValid = action.payload.isValid;
                });

                return nextState;

            // track which stage and section we have open
            case 'SET_ACTIVE':
                return {
                    ...state,
                    active: {
                        ...state.active,
                        ...action.payload,
                    },
                };

            case 'CHECK_FORM_STAGES':
                const { quoteData } = action;

                const checkStage = (stage, index) => {
                    // hide "Insurance Declaration" stage if insurer data empty
                    if (quoteData.insurer?.length === 0 && stage.stage === 'Insurance Declaration') {
                        return false;
                    }

                    // hide "Privacy & Consent" stage if financier data empty
                    if (quoteData.financier?.length === 0 && stage.stage === 'Privacy & Consent') {
                        return false;
                    }

                    return true;
                };

                return {
                    ...state,
                    financier_id: quoteData.financier?.id,
                    formData: state.formData.filter((stage, index) => checkStage(stage, index)),
                };

            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(appReducer, initialState);

    useEffect(() => {
        console.log('APP_CONTEXT');
        // eslint-disable-next-line
    }, []);

    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
