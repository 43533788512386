import { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Box, Flex, Link, Text } from '@theme-ui/components';
import BarLoader from 'react-spinners/BarLoader';
import { Icon } from 'components/Common/Icon';
import { getBorderColor } from './helpers';
import { theme } from 'theme';
import { Error } from '../Error';
import { Label } from '../Label';

export const FileUpload = ({ name, apiName, label, required, sx, onChange, ...props }) => {
    // eslint-disable-next-line
    const [field, meta, { setValue, setTouched, setError }] = useField(name, {
        type: 'file',
    });
    const [uploading, setUploading] = useState(false);
    const { setErrors } = useFormikContext();

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: '.pdf,.jpg,.jpeg,.png',
        onDropRejected: async (fileRejections) => {
            await setTouched(true);
            setError('Incorrect file format. Must be PDF, PNG or JPG.');
        },
        onDropAccepted: async (acceptedFiles) => {
            const file = acceptedFiles[0];
            setUploading(true);

            try {
                await onChange({
                    name,
                    value: file,
                    type: 'file',
                    callback: ({ value, url }) => {
                        // Set the field value first then set uploading to false
                        if (value || url) {
                            setValue(value || url);
                        }
                        setUploading(false);
                    },
                });
            } catch (error) {
                setUploading(false);
                setErrors({ [name]: 'Upload failed. Please try again.' });
            }
        },
    });

    const renderFileName = () => {
        if (uploading) {
            return 'Uploading...';
        }

        if (field.value) {
            return (
                <Link
                    href={field.value}
                    target="_blank"
                    sx={{
                        transition: 'easeDefault',
                        textDecoration: 'none',
                        color: 'grayDark',
                        '&:hover': {
                            color: 'primary',
                        },
                        lineHeight: '20px',
                    }}
                >
                    View uploaded file
                </Link>
            );
        }

        return null;
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Error
                text={meta.error}
                isVisible={meta.error && meta.touched}
                sx={{
                    position: 'absolute',
                    right: '13px',
                    top: '7px',
                    zIndex: 20,
                }}
            />
            <Box
                sx={{
                    cursor: uploading ? 'progress' : 'pointer',
                    pointerEvents: 'auto',
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                <Flex
                    sx={{
                        bg: 'white',
                        borderRadius: '4px',
                        px: [3, 4],
                        py: ['20px', '27px'],
                        mb: 3,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        transition: 'easeDefault',
                        color: 'primary',
                        border: `1px dashed ${getBorderColor({
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                        })}`,
                        pointerEvents: uploading ? 'none' : 'auto',
                        '&:hover': {
                            borderColor: '#959595',
                            svg: {
                                // color: 'secondary',
                            },
                        },
                    }}
                    {...getRootProps()}
                >
                    <Box tabIndex="-1">
                        <Label
                            htmlFor={field.name}
                            tabIndex="-1"
                            sx={{
                                pointerEvents: 'none',
                                fontSize: 2,
                                lineHeight: '20px',
                                mb: 0,
                            }}
                            text={`${label}${required ? '*' : ''}`}
                        />

                        <input {...getInputProps()} />
                        {field.value && (
                            <Box sx={{ mt: 1 }}>
                                <Flex sx={{ position: 'relative', zIndex: 5 }}>
                                    {!uploading && (
                                        <Icon
                                            as="button"
                                            icon="cross"
                                            type="button"
                                            size="20px"
                                            sx={{
                                                mr: 1,
                                                border: 'none',
                                                bg: 'transparent',
                                                p: 0,
                                                cursor: 'pointer',
                                                transition: 'easeDefault',
                                                color: 'grayDark',
                                                '&:hover': {
                                                    color: 'secondary',
                                                    transform: 'scale(1.15)',
                                                },
                                            }}
                                            onClick={(e) => {
                                                onChange &&
                                                    onChange({
                                                        name: apiName,
                                                        value: '',
                                                    });
                                                setValue('');
                                                e.stopPropagation();
                                            }}
                                        />
                                    )}

                                    <Text
                                        as="p"
                                        variant="label"
                                        sx={{
                                            position: 'relative',
                                            zIndex: 5,
                                            cursor: 'initial',
                                            mb: 0,
                                            color: 'grayDark',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {renderFileName()}
                                    </Text>
                                </Flex>
                            </Box>
                        )}
                    </Box>
                    {uploading ? (
                        <BarLoader size="20px" color={theme.colors.primary} />
                    ) : (
                        <Icon
                            color="secondary"
                            icon={field.value && field.value !== 'processing' ? 'tick' : 'upload'}
                            sx={{
                                width: [13, '16px'],
                                height: [13, '16px'],
                                svg: {
                                    width: '100%',
                                    height: '100%',
                                },
                            }}
                        />
                    )}
                </Flex>
            </Box>
        </Box>
    );
};
