import { useQuery } from 'react-query';
import api from 'config/api';

export const useAddressSearch = (searchText) => {
    return useQuery(
        ['getLoadAvailableAddresses', searchText],
        async () => {
            const response = await api.get(`/gnaf/autocomplete?query=${searchText}`);
            return response.data;
        },
        {
            enabled: searchText?.length >= 2,
            staleTime: 60000,
            cacheTime: 3600000,
        }
    );
};
