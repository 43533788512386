import { useQuery } from 'react-query';
import api from 'config/api';

export const useAddressDetail = (addressPid) => {
    const toSentenceCase = (str) => {
        if (!str) return '';
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return useQuery(
        ['getAddressDetailByPid', addressPid],
        async () => {
            if (!addressPid) return null;

            const response = await api.get(`/gnaf/${addressPid}`);
            const addressDetail = response.data.data;

            const combineStreetAddress = () => {
                const components = [];

                // Add street number
                const streetNum = [addressDetail.number_first_prefix, addressDetail.number_first, addressDetail.number_first_suffix].filter(Boolean).join('');

                if (addressDetail.number_last) {
                    const lastNum = [addressDetail.number_last_prefix, addressDetail.number_last, addressDetail.number_last_suffix].filter(Boolean).join('');
                    components.push(`${streetNum}-${lastNum}`);
                } else {
                    components.push(streetNum);
                }

                components.push(toSentenceCase(addressDetail.street_name), toSentenceCase(addressDetail.street_type_abbr));

                return components.filter(Boolean).join(' ');
            };

            const combineSecondaryComponents = () => {
                const components = [];

                if (addressDetail.lot_number) {
                    const lotNum = [addressDetail.lot_number_prefix, addressDetail.lot_number, addressDetail.lot_number_suffix].filter(Boolean).join('');
                    components.push(`LOT ${lotNum}`);
                }

                if (addressDetail.flat_number) {
                    const flatNum = [addressDetail.flat_number_prefix, addressDetail.flat_number, addressDetail.flat_number_suffix].filter(Boolean).join('');
                    components.push(`${toSentenceCase(addressDetail.flat_type || 'Unit')} ${flatNum}`);
                }

                if (addressDetail.level_number) {
                    const levelNum = [addressDetail.level_number_prefix, addressDetail.level_number, addressDetail.level_number_suffix].filter(Boolean).join('');
                    components.push(`${toSentenceCase(addressDetail.level_type || 'Level')} ${levelNum}`);
                }

                if (addressDetail.building_name) {
                    components.push(toSentenceCase(addressDetail.building_name));
                }

                return components.filter(Boolean).join(', ');
            };

            // Transform the API response to match the expected format
            return {
                address_detail_pid: addressPid,
                address_1: combineStreetAddress().trim(),
                address_2: combineSecondaryComponents(),
                suburb: toSentenceCase(addressDetail.locality_name),
                state: addressDetail.state_abbreviation,
                postcode: addressDetail.postcode,
            };
        },
        {
            enabled: !!addressPid,
            staleTime: 60000,
            cacheTime: 3600000,
        }
    );
};
