import { useMutation, useQueryClient } from 'react-query';
import { useRef } from 'react';
import _get from 'lodash.get';
import _set from 'lodash.set';
import produce from 'immer';
import { toast } from 'react-toastify';
import api from 'config/api';

export const useSubmitQuoteData = () => {
    const queryClient = useQueryClient();
    const pendingSubmissions = useRef(new Set());

    // remove the opening and closing "['" and "']" as the server cannot handle this
    // but they need to be set like this on the frontend  so we can keep for the formik values data structure flat (not a nested object)
    const appendRepeaterData = (formData, name, value) => {
        if (!value) return;
        Array.isArray(value) &&
            value.forEach((row, index) => {
                if (!row) return;
                Object.keys(row).forEach((key) => {
                    formData.append(`${name}[${index}][${key}]`, row[key]);
                });
            });
    };

    return useMutation(
        async ({ name, value, repeater, callback, uploadSettled, onError, quoteUuid, notForm, type }) => {
            // for address updates, check if there's already a pending submission
            if (type === 'address') {
                const submissionKey = `${quoteUuid}-${name}`;
                if (pendingSubmissions.current.has(submissionKey)) {
                    return;
                }
                pendingSubmissions.current.add(submissionKey);
            }

            try {
                // For notForm updates, return early with mock response
                if (notForm) {
                    return {
                        data: {
                            success: true,
                            data: {
                                required_fields: queryClient.getQueryData('quote')?.required_fields || [],
                                quote_status_id: queryClient.getQueryData('quote')?.quote_status_id,
                            },
                        },
                        type,
                        value,
                        name,
                    };
                }

                let formData = new FormData();

                if (type === 'address') {
                    const basePath = name;
                    Object.entries(value).forEach(([key, val]) => {
                        if (val !== null && val !== undefined) {
                            // Make sure we're explicitly handling address_detail_pid
                            if (key === 'address_detail_pid' || val !== '') {
                                formData.append(`${basePath}[${key}]`, val);
                            }
                        }
                    });
                } else if (!repeater) {
                    formData.append(name, value);
                } else {
                    appendRepeaterData(formData, name, value);
                }

                let response = await api.post(`/quote/${quoteUuid}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (response.data.success) {
                    // For file uploads, we need to handle the case where the URL isn't immediately available
                    if (type === 'file') {
                        // make sure we call both callback and uploadSettled
                        if (response.data.data?.[name]) {
                            if (callback) {
                                await callback({
                                    value: response.data.data[name],
                                    url: response.data.data[name],
                                    fieldName: name,
                                });
                            }
                            // always call uploadSettled after callback
                            if (uploadSettled) {
                                uploadSettled();
                            }
                        }
                    } else {
                        callback && (await callback(_get(response.data.data, name)));
                        uploadSettled && uploadSettled();
                    }

                    toast.info('Quote progress saved.', {
                        toastId: 'quote-saved',
                    });
                }

                // Add metadata for onSuccess handler (for address type)
                if (type === 'address') {
                    response.type = type;
                    response.value = value;
                    response.name = name;
                }

                return response;
            } catch (e) {
                const { status } = e.response || {};
                if (e.response?.data?.errors) {
                    onError && onError(e.response.data.errors);
                }

                switch (status) {
                    case 413:
                        onError && onError('File size limit exceeded. Must be below 10MB.');
                        break;
                    default:
                        break;
                }
                throw e;
            } finally {
                if (type === 'address') {
                    const submissionKey = `${quoteUuid}-${name}`;
                    pendingSubmissions.current.delete(submissionKey);
                }
            }
        },
        {
            onMutate: async (data) => {
                // don't set the value if it's a file - as we set the URL returned above in callback
                if (data.type === 'file') return;

                const previousQuoteData = queryClient.getQueryData('quote');

                const nextState = produce(previousQuoteData, (draftState) => {
                    // handle postal address sync when has_different_postal is set to false
                    if (data.name === 'personal[postal_address][has_different_postal]' && data.value === false) {
                        const mainAddress = _get(draftState, 'personal.address');
                        const addressFields = ['address_detail_pid', 'address_1', 'address_2', 'suburb', 'state', 'postcode', 'country_id'];

                        addressFields.forEach((field) => {
                            _set(draftState, `personal.postal_address.${field}`, mainAddress[field]);
                        });
                    }

                    if (data.type === 'address' && data.value) {
                        _set(draftState, data.name, data.value);
                    } else {
                        _set(draftState, data.name, data.value);
                    }
                });

                queryClient.setQueryData('quote', nextState);

                return { previousQuoteData };
            },
            onSuccess: async (data) => {
                await queryClient.cancelQueries('quote');

                if (data?.data?.success) {
                    queryClient.setQueryData('quote', (oldData) => {
                        if (!oldData) return oldData;

                        // only update the required fields and quote status ID
                        // if we update the whole object and the user continues typing in fields before the data is saved, it will be overwritten with this response
                        return produce(oldData, (draftState) => {
                            if (data.data.data?.required_fields !== undefined) {
                                draftState.required_fields = data.data.data.required_fields;
                            }
                            if (data.data.data?.quote_status_id !== undefined) {
                                draftState.quote_status_id = data.data.data.quote_status_id;
                            }

                            if (data.type === 'address' && data.value) {
                                _set(draftState, data.name, data.value);
                            }
                        });
                    });
                }
            },
        },
        {
            onError: (error) => {
                console.error('[Debug] Mutation error:', {
                    error,
                    response: error.response,
                    message: error.message,
                });
            },
        }
    );
};
