import { Text } from '@theme-ui/components';
import styled from '@emotion/styled';

export const ErrorStyled = styled(Text)`
    color: red;
    font-weight: 500;
    margin: 0;
    font-size: ${(props) => (props.showAsText ? '14px' : '10px')};
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    pointer-events: none;
    margin-bottom: ${(props) => (props.showAsText ? '4px' : '8px')};
    padding-left: ${(props) => (props.showAsText ? '8px' : '0')};
    text-align: left;
`;

export const Error = ({ isVisible, sx, ...props }) => {
    if (!isVisible) {
        return '';
    }

    return (
        <ErrorStyled as="p" isVisible={isVisible} showAsText={props.showAsText} sx={{ zIndex: 10, ...sx }}>
            {props.text}
        </ErrorStyled>
    );
};
