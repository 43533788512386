import React from 'react';
import { useField } from 'formik';
import { Box } from 'theme-ui';
import { Error } from '../Error';
import { useDebounce } from 'hooks/useDebounce';
import styled from '@emotion/styled';
import { Input } from '@theme-ui/components';

export const InputStyled = styled(Input)`
    padding: 0;
    height: 0;
    border: 0;

    &::-ms-clear {
        display: none;
    }
`;

// forward ref so we can focus with parent components
export const AddressPid = React.forwardRef(({ name, apiName, validationError, onChangeOverride, onChange, groupSx, ...props }, ref) => {
    const [field, meta, { setValue }] = useField(name);
    const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);

    const fieldProps = { ...field, ...props };
    // add check for empty address_detail_pid
    const showError = validationError || !field.value;
    return (
        <Box sx={{ position: 'relative', ...groupSx }}>
            {showError && <Error text={meta.error} isVisible={true} showAsText />}

            <InputStyled
                {...fieldProps}
                {...props}
                type="text"
                disabled
                readOnly
                ref={ref}
                invalid={meta.error}
                onChange={(event) => {
                    const { value } = event.target;
                    if (onChangeOverride) {
                        onChangeOverride(event);
                    } else {
                        setValue(value);

                        onChange &&
                            debouncedSave({
                                name: apiName,
                                value: value === null ? '' : value,
                            });
                    }
                }}
            />
        </Box>
    );
});
